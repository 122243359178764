import $ from 'jquery';
import Cookies from 'js-cookie';
import ScrollMagic from 'scrollmagic';
import './form.js';
import './app.js';

window.jQuery = window.$ = $;
var controller = new ScrollMagic.Controller();

$(function() {
    $.fn.extend({
        toggleText: function(a, b){
            return this.text(this.text() == b ? a : b);
        }
    });

    // Set cookies
    // Accept cookies
    $("#cookies-button").on("click", function() {
        Cookies.set('privacy-policy', true);
        $("#cookies-modal").hide();
    });

    if(Cookies.get('privacy-policy')) {
        $("#cookies-modal").hide();
    } else {
        $("#cookies-modal").show();
    }

    // Hide cookie notice close (Temp)
    $("#close").on("click", function(){
        $("#cookies-modal").hide();
    });

    $(".modal-leaving").on("click", function(e){
        var newURL = e.target.href;
        $(".overlay, #leaving").show();
        $("body").addClass("modal-active");
        $("#leaving").find("a.button").attr("href", newURL);
        event.preventDefault();
    });

    $("#leaving .button").on("click", function() {
        // Remove the modal after the user opens a new tab
        $(".overlay, .modal").hide();
    });

    if(($(this).scrollTop() > 200)) {
        // Only shift the navbar on mobile
        $("header").addClass("scrolled");
        $("nav.primary").addClass("scrolled");
        $(".mobile-nav").addClass("scrolled");
        $("#container-nav").addClass("scrolled");
        $(".disclaimer").css("background", "#000101");
        $(".main-nav").css("background", "linear-gradient(90deg, #003940 0%, #006062 49.94%, #003840 99.45%)");
    }

    // Convert navbar to solid background
    $(window).on("scroll", function() {
        // Make the navbar solid background after scrolling down the page
        if(($(window).width() < 768 && $(this).scrollTop() > 200) || ($(window).width() > 769 && $(this).scrollTop() > 200)) {
            // Only shift the navbar on mobile
            $("header").addClass("scrolled");
            $("nav.primary").addClass("scrolled");
            $(".mobile-nav").addClass("scrolled");
            $("#container-nav").addClass("scrolled");
            $(".disclaimer").css("background", "#000101");
            $(".main-nav").css("background", "linear-gradient(90deg, #003940 0%, #006062 49.94%, #003840 99.45%)");
        } else {
            $("header").removeClass("scrolled");
            $("nav.primary").removeClass("scrolled");
            $(".mobile-nav").removeClass("scrolled");
            $("#container-nav").removeClass("scrolled");
            $(".disclaimer").css("background", "");
            $(".main-nav").css("background", "");
        }
    });

    // Toggle button for individual item
    $(".item h3, .item p.description").on("click", function() {
        $(this).parent().find("h3").toggleClass('rotate');
        $(this).parent().find(".answer").toggleClass('opened');
    });

    $(".faq-accordion").on("click", function() {
        $(this).toggleClass('rotate');
        $(this).parent().toggleClass('item-opened');
        $(this).find(".answer").toggleClass('opened');
    });

    $(".mobile-nav").on("click", function() {
        $("nav.primary").toggleClass("opened");
        $(".mobile-nav").toggleClass("opened");
        if($(this).text() === "MENU") {
            $(".mobile-nav").text("✕");
        } else {
            $(".mobile-nav").text("MENU");
        }
    });

    $("nav.primary .dropdown").on("click", function() {
        $(this).toggleClass("opened");
    });

    $(".overlay, #nav-overlay-mobile").on("click", function() {
        $(".modal, aside, .overlay").hide();
        $(".mobile-nav, nav.primary").removeClass("opened");
        $(".mobile-nav").text("MENU");
    });

    $("a.return, span.close").on("click", function() {
        $(".modal, aside, .overlay").hide();
    });
    

    // Generate overlay isi
    var isiSection = $("#isi").find(".isi-section").html();
    $(".overlay-isi").append(isiSection);

    // Hide overlay ISI when the full ISI comes into view
    new ScrollMagic.Scene({triggerElement: "#isi", offset: -180})
        .on('enter', () => {
            $(".overlay-isi").hide();
        })
        .on('leave', () => {
            $(".overlay-isi").show();
        })
        .addTo(controller);
    
    // ++++++++++++ TOOLTIPS ++++++++++++ (without jQuery ... WOW)
    // Find all ref objects and create tippy instances for each 
    var refs = document.querySelectorAll('[data-ref]');
    refs.forEach((ref) => {
        const ref_obj = ref_data.find(({ref_id}) => ref_id === ref.dataset.ref );

        tippy('[data-ref="'+ref_obj.ref_id+'"]', {
            content: '<span class="close-btn"></span><div class="ref_title">'+ref_obj.ref_title+'</div><p class="ref_content">'+ref_obj.ref_def+'</p>',
            allowHTML: true,
            trigger: 'click'
        });
    });

    // ++++++++++++ VORANIGO SPEECH ++++++++++++
    if(document.getElementById('say-voranigo')) {
        let say_voranigo = document.getElementById('say-voranigo');
        let msg = new SpeechSynthesisUtterance('Vora knee go');
        say_voranigo.addEventListener('click', function(){
            window.speechSynthesis.speak(msg);
        });
    }

});

// Definitions 
const ref_data = [
    { ref_id:"ref_diagnosis", ref_title: "Diagnosis:", ref_def: "A diagnosis is the process of identifying a disease, condition, or injury from its signs and symptoms." },
    { ref_id:"ref_mutation", ref_title: "Mutation:", ref_def: "A change within DNA that can cause proteins to act differently than they normally would. Some mutations in specific genes can lead to cancer." },
    { ref_id:"ref_idh_gene", ref_title: "IDH gene:", ref_def: "The genetic code for enzymes involved in producing energy for the body." },
    { ref_id:"ref_prognosis", ref_title: "Prognosis:", ref_def: "A prognosis is the likely outcome or course of a disease." },
    { ref_id:"ref_mri", ref_title: "MRI:", ref_def: "An MRI is a procedure that uses radio waves, a powerful magnet, and a computer to make a series of detailed pictures of areas inside the body." },
    { ref_id:"ref_neuro-oncologist", ref_title: "Neuro-oncologist:", ref_def: "A doctor who specializes in treating patients with cancers or tumors of the central nervous system, including the brain." },
    { ref_id:"ref_radiologist", ref_title: "Radiologist:", ref_def: "A doctor who has special training in creating and interpreting pictures of areas inside the body." },
    { ref_id:"ref_neurosurgeon", ref_title: "Neurosurgeon:", ref_def: "A surgeon specializing in surgery of the central nervous system." },
    { ref_id:"ref_oncologist", ref_title: "Oncologist:", ref_def: "A doctor specializing in treating patients with cancers or tumors of the body." },
    { ref_id:"ref_neurologist", ref_title: "Neurologist:", ref_def: "A doctor who has special training in diagnosing and treating disorders of the nervous system." },
    { ref_id:"ref_pathologist", ref_title: "Pathologist:", ref_def: "A doctor who specializes in diagnosing and classifying diseases by lab tests and by looking at tissues and cells with a microscope." },
    { ref_id:"ref_ihc", ref_title: "IHC:", ref_def: "IHC, or immunohistochemistry, is a staining method used by pathologists to see specific markers that a cancer cell has to assist with diagnosis." },
    { ref_id:"ref_ngs", ref_title: "NGS:", ref_def: "NGS, or next-generation sequencing, is a technique used to learn the order of the building blocks in a person’s DNA." },
    { ref_id:"ref_enzymes", ref_title: "Enzymes:", ref_def: "An enzyme is a protein that speeds up chemical reactions in the body." },
    { ref_id:"ref_clinical-trial", ref_title: "Clinical trial:", ref_def: "Clinical trials are research studies to test new drugs or other treatments against current or standard treatments." }
]
