import Pristine from "pristinejs";

/* globals Pristine */
if(window.location.pathname.split(".")[0] === "/connect-with-a-representative") {
    window.addEventListener("load", function () {
        // FORM VALIDATOR
        var form = document.getElementById("sign_up");
        var pristine = new Pristine(form);
        var specialty = document.getElementById("specialty");
        var request = document.getElementById("request");
        var phone = document.getElementById("phone-number");

        function isValid(p) {
            var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
            var digits = p.toString().replace(/\D/g, "");
            return phoneRe.test(digits);
        }

        pristine.addValidator(phone, function(value) {
            console.log(value)
            if($("#phonePref").is(':checked') && !isValid(value)) {
                return false;
            }
            return true;
        }, "To be contacted by phone, please enter your phone number.", 2, false);

        form.addEventListener("submit", function (e) {
            e.preventDefault();
            var valid = pristine.validate();
            
            if (valid) {
                const mapping = {
                    email: "SRV_Email_Address__c",
                    name: "SRV_First_Name__c",
                    lastName: "SRV_Last_Name__c",
                    specialty: "SRV_Specialty__c",
                    institution: "SRV_Institution__c",
                    zipCode: "SRV_Zip_Code__c",
                };
                const elements = event.target.elements;
                const cForm = {
                    // the institution field is only present for Sign up for Updates.
                    SRV_Web_Form_Type__c: "Sign up for Updates",
                };
                Object.getOwnPropertyNames(elements).forEach((key) => {
                    if (key in mapping) {
                        console.log("key: " + key);
                        if (
                            key == "specialty" &&
                            elements[key].value == "Select a specialty..."
                        ) {
                            cForm[mapping[key]] = "";
                        } else {
                            cForm[mapping[key]] = elements[key].value;
                        }
                    }
                });
                console.log("form", cForm);
                // Will need update
                SRV_GliomaDSA.saveWebForm(cForm, function (response, event) {
                    if (event.status) {
                        if (response.status === 200) {
                            console.log("success...");
                            window.location.href = "/connect-with-a-representative-thank-you";
                        } else {
                            alert(response.msg);
                        }
                    }
                });
            }
        });
    
        specialty.addEventListener("change", function () {
            if (specialty.value !== "") {
                specialty.classList.add("selected");
            } else {
                specialty.classList.remove("selected");
            }
        });

        request.addEventListener("change", function () {
            if (request.value !== "") {
                request.classList.add("selected");
            } else {
                request.classList.remove("selected");
            }
        });
    });    
}